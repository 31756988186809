<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle title="Profile" slot="title" link="/helpContent/User List" />
    <ValidationObserver ref="validator" >
      <FormRow>
        <div class="col-sm-2">
            <FormSelect label="Select Status" :items="statuses" item-name="name" item-value="id" v-model="filtering.statusSelected" />
        </div>
        <div class="col-sm-3">
            <InputText v-model="filtering.userName" style="width: 200px" label="User Name" />
        </div>
        <div class="col-sm-3">
            <FormButton style="margin-top: 5px" type="primary" @click="search" :rounded="true">
            <Icon type="ios-search" />
        </FormButton>
        </div>
      </FormRow>
    </ValidationObserver>
    <DataTable
      :actions="true"
      :data="listData"
      :columns="tableConfig"
      :is-loading="isLoading"
      @on-selection-change="selectChange"
      :pagination="pagination"
      @on-page-change="pageChange"
      @on-sort-change="sortChange"
      >
      <template slot="actionsLeft">
        <TableButton @click="() => edit()">
            <img :src="require(`Assets/images/view.png`)"/>
          View
        </TableButton>
      </template>
    </DataTable>
  </FormWrapper>
</template>
<script>
  import FormWrapper from "Components/form/FormWrapper";
  import PageTitle from "Components/layout/PageTitle";
  import DataTable from "Components/DataTable";
  import loadingMixin from "Mixins/loadingMixin";
  import { teamUserList } from "../api";
  import validateSelectedRows from "Mixins/validateSelectedRows";
  import { ValidationObserver } from 'vee-validate'
  import tableConfig from "./table";
  export default {
    name: "List",
    mixins: [loadingMixin, validateSelectedRows],
    components: {
      FormWrapper,
      DataTable,
      PageTitle,
      ValidationObserver
    },
    computed: {
      userData() {
        return this.$store.state.user.user;
      },
    },
    data() {
      return {
        filtering: {
          statusSelected: null,
          userName: ''
        },
        sorting: {
          key: "id",
          order: "asc",
        },
        pagination: {
          perPage: 10,
          page: 1,
          total: 0,
        },
        tableConfig,
        statuses: [
            {
                'id':'All',
                'name':'All Users'
            },
            {
                'id':'Active',
                'name':'Active Users'
            },{
                'id':'Deactive',
                'name':'Deactive Users'
            }
        ],
        listData: [],
        selectedRows: [],
        showMessage: {
          isVisible: false,
          message: "",
        },
      };
    },
    created() {
      this.getDataByFilter();
    },
    methods: {
      edit(){
        if (this.validateSelectedLength(1)) {
          this.$router.push({ path: `/userInformation/${btoa(this.selectedRows[0].id)}/${btoa(1)}` });
        }
      },
      search() {
        this.pagination.page = 1;
        this.getDataByFilter();
      },
      selectChange(data) {
        this.selectedRows = data;
      },
      sortChange(data) {
        this.sorting.key = data.key;
        this.sorting.order = data.order;
        this.getDataByFilter();
      },
      getDataByFilter() {
        setTimeout(() => {
          // this.showLoader();
          const { key, order } = this.sorting;
          const { perPage, page } = this.pagination;
          const filters = {};
          if(this.filtering.statusSelected === null){
              this.filtering.statusSelected = "Active";
          }
          if (this.filtering.statusSelected) {
              filters.type = this.filtering.statusSelected;
          }
          if (this.filtering.userName) {
              filters.name = this.filtering.userName;
          }
          filters.managerId = this.userData.user_id
          const data = {
            filterjson: {
              filter: [filters],
              sort: [{ key, order }],
              paging: [
                {
                  startIndex: page,
                  pagesize: perPage,
                },
              ],
            },
          };
          teamUserList(data)
            .then(this.handleResponse)
            .catch(this.handleError);
        }, 50);
      },
      pageChange(page) {
        this.pagination.page = page;
        this.getDataByFilter();
      },
      handleResponse(response) {
        this.hideLoader();
        this.listData = response.data;
        if (response.NumberOfRecords) {
          this.pagination.total = response.NumberOfRecords;
        }        
      },
    },
  };
</script>

<style lang="scss" module>
  .popupRow {
    padding: 5px 0 15px;
  }
  .pagination {
    margin: 10px 0 0;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    line-height: 26px;
  }

  .paginationText {
    margin: 0 20px;
  }

  .paginationButton {
    cursor: pointer;
    width: 26px;
    text-align: center;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
      box-shadow 200ms ease;
  }
  .paginationButton:hover {
    background: #57a3f3;
    color: #fff;
    &:hover {
      box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
        0 4px 15px 0 rgba(0, 0, 0, 0.15);
    }
  }

  .filter {
    margin: -10px 0 10px;
  }

  .filterItem {
    display: inline-block;
    margin: 0 15px 15px 0;
  }

  .filterItemCheckbox {
    flex-basis: auto;
    align-self: flex-start;
    margin-top: 13px;
  }
</style>
